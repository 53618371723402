import {
  faChartBullet,
  faEnvelope,
  faGavel,
  faHandHoldingDroplet,
  faSeedling,
} from "@fortawesome/pro-light-svg-icons";
import { t, Trans } from "@lingui/macro";
import { Box, CssBaseline, Grid, Link, Stack, Typography, Button } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";
import ArrowButton from "../components/ArrowButton";
import Container from "../components/Container";
import CustomizedIcon from "../components/CustomizedIcon";
import Footer from "../components/footer";
import Modal from "../components/Modal";
import hero from "../images/expansion-awards/hero.png";
import icon1 from "../images/expansion-awards/icon1.png";
import icon2 from "../images/expansion-awards/icon2.png";
import icon3 from "../images/expansion-awards/icon3.png";
import icon4 from "../images/expansion-awards/icon4.png";
import mainstreetLogo from "../images/mainstreet_contrast.png";
import logoPremios from "../images/expansion-awards/premios_expansion.png";
import { theme } from "../theme";

const ITEMS = [
  {
    icon: faChartBullet,
    color: "#FFD07C",
    title: t`What are the categories?`,
    content: (
      <ul style={{ margin: 0 }}>
        <li>Best ESG Asset Manager</li>
        <li>Best ESG Boutique Asset Manager</li>
        <li>Best Environmental Fund</li>
        <li>Best Spanish ESG Asset Manager</li>
      </ul>
    ),
  },
  {
    icon: faHandHoldingDroplet,
    color: "#C22C74",
    title: t`What is the deadline?`,
    content: (
      <Box>
        Deadlines for submissions is March 15, please be advised that MSP may
        have to schedule a due diligence call to go through your submission and
        the documents you provide.
      </Box>
    ),
  },
  {
    icon: faGavel,
    color: "#F49F6C",
    title: t`How are the winners chosen?`,
    content: (
      <Box>
        MainStreet Partners, a specialized ESG advisory and Allfunds partner,
        will evaluate and filter the submissions to create a list of finalists.
        <br />
        <br />
        Main Street Partners will use its unique proprietary methodology to
        evaluate a fund’s Sustainability level across three pillars: Asset
        Manager, Strategy, Portfolio.
        <br />
        <br />
        Once the evaluation is complete, MainStreet Partner's shortlist will
        be submitted to expert panel that will cast a vote to decide the winners.
        <br />
        <br />
        <Typography color="text.light">
          Learn more about MSPs scoring{" "}
          <span
            style={{
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() =>
              window.open(
                "https://www.mspartners.org/methodologies/#:~:text=The%20final%20MainStreet%20ESG%20Score,issuers%20and%20quarterly%20for%20funds.",
                "_blank",
              )}
          >
            here
          </span>
          .
        </Typography>
      </Box>
    ),
  },
  {
    icon: faSeedling,
    color: "#447CCB",
    title: t`What are the minimum requirements for entry?`,
    content: (
      <ul style={{ margin: 0 }}>
        <li>
          Funds must have a track record of at least 3 years. Minimum assets
          under management (AUM) of 20M€.
        </li>
        <li>All fund submitted must be SFDR Art. 8 or 9.</li>
        <li>
          All registered share classes available for sale in Spain will be
          considered.
        </li>
        <li>
          In addition to being registered for sale in Spain, funds must have the
          “traspaso” condition from the CNMV.
        </li>
        <li>Boutique Fund Houses are those that have AUM &lt;10bn€</li>
        <li>Funds must be accessible to retail investors.</li>
      </ul>
    ),
  },
];

const BESTS = [
  {
    icon: icon1,
    color: "rgba(255, 208, 124, 1)",
    title: t`Best ESG Fund House`,
    link: "https://3e87xa7m.forms.app/premios-exp-allfunds-fund-house-1-1",
    content: (
      <>
        <ul>
          <li>
            When did you start to implement SRI / ESG / Thematic / Impact
            strategies & products?
          </li>
          <li>
            What is the current AUM invested in Article 8 and/or 9 (or
            equivalent) strategies, and as % of total AUM?
          </li>
          <li>
            Have you launched new or closed any SRI / ESG / Impact / Thematic
            strategies in the last twelve months?
          </li>
          <li>
            Have you supported any new major shareholder alliances /
            collaborations in the last twelve months?
          </li>
          <li>How are you leading innovation within the industry?</li>
          <li>What are the resources dedicated to Sustainable Investing?</li>
          <li>
            Who is responsible for Sustainability / ESG oversight and
            implementation?
          </li>
          <li>
            What is your approach to internal/external ESG specific
            education/training?
          </li>
          <li>What is your definition of a "Sustainable Investment"?</li>
        </ul>
        <Typography>Supporting documents:</Typography>
        <ul>
          <li>
            Corporate Sustainability/ESG Investment Policy and/or Presentation.
          </li>
          <li>Latest Company level Sustainability Report (if you have any).</li>
          <li>Engagement/Stewardship/Proxy Voting Policies.</li>
          <li>
            Latest Engagement/Stewardship/Proxy Voting Reports (if you have
            any).
          </li>
          <li>Diversity, Equality and Inclusion Policy (if you have any).</li>
          <li>Remuneration Policy.</li>
        </ul>
      </>
    ),
  },
  {
    icon: icon2,
    color: "rgba(83, 173, 152, 1)",
    title: t`Best ESG Boutique Fund House`,
    link: "https://3e87xa7m.forms.app/premios-exp-allfunds-fund-house-1-1",
    content: (
      <>
        <ul>
          <li>
            When did you start to implement SRI / ESG / Thematic / Impact
            strategies & products?
          </li>
          <li>
            What is the current AUM invested in Article 8 and/or 9 (or
            equivalent) strategies, and as % of total AUM?
          </li>
          <li>
            Have you launched new or closed any SRI / ESG / Impact / Thematic
            strategies in the last twelve months?
          </li>
          <li>
            Have you supported any new major shareholder alliances /
            collaborations in the last twelve months?
          </li>
          <li>How are you leading innovation within the industry?</li>
          <li>What are the resources dedicated to Sustainable Investing?</li>
          <li>
            Who is responsible for Sustainability / ESG oversight and
            implementation?
          </li>
          <li>
            What is your approach to internal/external ESG specific
            education/training?
          </li>
          <li>What is your definition of a "Sustainable Investment"?</li>
        </ul>
        <Typography>Supporting documents:</Typography>
        <ul>
          <li>
            Corporate Sustainability/ESG Investment Policy and/or Presentation.
          </li>
          <li>Latest Company level Sustainability Report (if you have any).</li>
          <li>Engagement/Stewardship/Proxy Voting Policies.</li>
          <li>
            Latest Engagement/Stewardship/Proxy Voting Reports (if you have
            any).
          </li>
          <li>Diversity, Equality and Inclusion Policy (if you have any).</li>
          <li>Remuneration Policy.</li>
        </ul>
      </>
    ),
  },
  {
    icon: icon3,
    color: "rgba(68, 124, 203, 1)",
    title: t`Best Spanish ESG Fund House`,
    link: "https://3e87xa7m.forms.app/premios-exp-allfunds-spanish-fh",
    content: (
      <>
        <ul>
          <li>
            When did you start to implement SRI / ESG / Thematic / Impact
            strategies & products?
          </li>
          <li>
            What is the current AUM invested in Article 8 and/or 9 (or
            equivalent) strategies, and as % of total AUM?
          </li>
          <li>
            Have you launched new or closed any SRI / ESG / Impact / Thematic
            strategies in the last twelve months?
          </li>
          <li>
            Have you supported any new major shareholder alliances /
            collaborations in the last twelve months?
          </li>
          <li>How are you leading innovation within the industry?</li>
          <li>What are the resources dedicated to Sustainable Investing?</li>
          <li>
            Who is responsible for Sustainability / ESG oversight and
            implementation?
          </li>
          <li>
            What is your approach to internal/external ESG specific
            education/training?
          </li>
          <li>What is your definition of a "Sustainable Investment"?</li>
        </ul>
        <Typography>Supporting documents:</Typography>
        <ul>
          <li>
            Corporate Sustainability/ESG Investment Policy and/or Presentation.
          </li>
          <li>Latest Company level Sustainability Report (if you have any).</li>
          <li>Engagement/Stewardship/Proxy Voting Policies.</li>
          <li>
            Latest Engagement/Stewardship/Proxy Voting Reports (if you have
            any).
          </li>
          <li>Diversity, Equality and Inclusion Policy (if you have any).</li>
          <li>Remuneration Policy.</li>
        </ul>
      </>
    ),
  },
  {
    icon: icon4,
    color: "rgba(194, 44, 116, 1)",
    title: t`Best Enviromental Fund`,
    link: "https://3e87xa7m.forms.app/2024-expansion-allfunds-awards-best-env-fund",
    content: (
      <>
        <ul>
          <li>
            Why do you believe the Fund would be a worthy winner of the award?
            Please highlight at least three key aspects that differentiate you
            from peers.
          </li>
          <li>Who is responsible for managing the Fund?</li>
          <li>What is the current Fund AUM versus expected capacity?</li>
          <li>Is the Fund classified as Article 6, 8 or 9 under SFDR?</li>
          <li>If applicable, what is the ESG approach best described as?</li>
          <li>
            Is there a formal minimum % in Sustainable Investments as defined by
            SFDR?
          </li>
          <li>
            Is there a formal minimum % in EU Taxonomy aligned investments?
          </li>
          <li>
            Is there quantitative and/or qualitative consideration of principal
            adverse impacts (PAI)?
          </li>
          <li>
            Does the Fund have a specific ESG integration or Sustainability
            policy?
          </li>
          <li>Does the Fund have an ESG benchmark?</li>
          <li>
            Does the Fund have an exclusion policy on the below sectors and if
            so, what is the threshold in terms of percentage of company revenues
            (or similar)? (UNGC violations, controversial weapons, coal,
            conventional weapons, tobacco, gambling, pornography)
          </li>
          <li>
            Does the fund apply Paris-Aligned or Climate Transition Benchmark exclusions in full?
          </li>
          <li>
            Does the Fund have a formal process to monitor controversial
            behaviours of the companies in its investable universe?
          </li>
          <li>
            Does the Fund have Engagement/Stewardship/Proxy Voting policies?
          </li>
          <li>
            Does the Fund have Engagement/Stewardship/Proxy Voting reporting?
          </li>
        </ul>
        <Typography>Supporting documents:</Typography>
        <ul>
          <li>Prospectus & KIID for primary institutional share class.</li>
          <li>Latest RFP/DDQ</li>
          <li>Latest presentation.</li>
          <li>Latest portfolio holdings (within the last two months)</li>
          <li>Latest factsheet</li>
          <li>Any strategy specific ESG reporting.</li>
          <li>European ESG Template (EET).</li>
        </ul>
      </>
    ),
  },
];

function ExpansionAwardsEsg() {
  const [bestShow, setBestShow] = useState(null);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            width: "100%",
            display: "block",
            height: "fit-content",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${hero})`,
          }}
        >
          <Box py={{ xs: 4, md: 10 }} px={{ xs: 2, md: 15 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} lg={6}>
                <Box
                  sx={{
                    width: "100%",
                    display: "block",
                    minHeight: " 300px",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${logoPremios})`,
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Stack spacing={1}>
                  <Typography color="white" fontSize={20}>
                    These awards recognize the premier investment funds and
                    pension plans of 2024 managed by the top management firms in
                    Spain.
                  </Typography>
                  <Typography color="white" fontSize={20}>
                    Below, you will find detailed instructions on how the awards
                    function, how to submit your entry, and the supporting
                    documents required.
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} textAlign="end">
                <img alt="mainstreet_logo" src={mainstreetLogo} />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box py={4}>
          <Container>
            <Grid container spacing={6} justifyContent="center">
              <Grid item>
                <Stack spacing={1} textAlign="center">
                  <Typography fontWeight={700}>Premios Fondos 2024</Typography>
                  <Typography fontSize={{ xs: 36, md: 48 }} fontWeight={700}>
                    Premios Fondos Expansión-Allfunds
                  </Typography>
                </Stack>
              </Grid>
              {ITEMS.map(({ icon, color, title, content }) => (
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <CustomizedIcon
                      size={28}
                      icon={icon}
                      padding={1}
                      color={color}
                    />
                    <Typography fontSize={24} fontWeight={700}>
                      {title}
                    </Typography>
                    <Typography color="text.light">{content}</Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
        <Box
          pt={8}
          pb={10}
          sx={{ backgroundColor: (theme) => theme.palette.neutral.lighter }}
        >
          <Container>
            <Grid container spacing={{ xs: 4, md: 2 }}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <Typography
                    fontWeight={700}
                    textAlign="center"
                    fontSize={{ xs: 36, md: 48 }}
                  >
                    How to submit an application
                  </Typography>
                  <Typography color="text.light" textAlign="center">
                    Please fill out the following surveys to submit an entry:
                  </Typography>
                </Stack>
              </Grid>
              {BESTS.map(({ icon, color, title, link }, index) => {
                return (
                  <Grid item xs={12} sm={6} md={3}>
                    <Stack
                      p={2}
                      pb={3}
                      spacing={1}
                      sx={{
                        height: "100%",
                        position: "relative",
                        borderRadius: "10px",
                        border: `1px solid ${color}`,
                      }}
                    >
                      <img alt={title} src={icon} width={24} height={24} />
                      <Typography fontWeight={700}>{title}</Typography>
                      <Box>
                        <Typography color="text.light">
                          Preview the questions and supporting documents
                          required{" "}
                          <span
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={() => setBestShow(BESTS[index])}
                          >
                            here
                          </span>
                        </Typography>
                      </Box>
                      <ArrowButton
                        isDark
                        title="Survey"
                        sx={{ position: "absolute", bottom: "-20px" }}
                        onClick={() => window.open(link, "_blank")}
                      />
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </Box>
        <Box py={2}>
          <Container>
            <Stack spacing={1} px={{ xs: 2, md: 6 }}>
              <CustomizedIcon
                size={28}
                padding={1}
                color="#375B8D"
                icon={faEnvelope}
                style={{ margin: "0 auto" }}
              />
              <Box textAlign="center" width={{ md: "80%", lg: "60%" }} style={{ marginLeft: "auto", marginRight: "auto" }}>
                <Typography color="text.light" variant="small">
                  If you encounter any difficulties with the forms, please
                  contact
                </Typography>{" "}
                <Link href="mailto: premiosexpansion@allfunds.com">
                  <Typography color="text.light" variant="small">
                    premiosexpansion@allfunds.com
                  </Typography>
                </Link>{" "}
                <Typography
                  color="text.light"
                  textAlign="center"
                  variant="small"
                >
                  for assistance. Kindly note that submissions sent via email
                  will not be considered.
                </Typography>
              </Box>
            </Stack>
          </Container>
        </Box>
        <Footer small />
        {bestShow && (
          <Modal
            open
            backgroundColor="white"
            onClose={() => setBestShow(null)}
            sx={{ width: "900px !important" }}
          >
            <Stack spacing={1}>
              <img
                alt="best_image"
                src={bestShow.icon}
                width={24}
                height={24}
              />
              <Typography fontSize={24} fontWeight={700}>
                {bestShow.title}
              </Typography>
              <Typography>
                Preview the questions and supporting documents required
              </Typography>
              <Stack overflow="auto" maxHeight="500px">
                <Typography>{bestShow.content}</Typography>
              </Stack>
            </Stack>
            <Stack justifyContent="flex-start" direction="row" pt={2}>
              <Button
                size="small"
                color="corporate"
                variant="contained"
                style={{borderRadius: "10px"}}
                sx={{
                  height: "50px",
                  boxShadow: "none",
                  fontWeight: "700",
                  "&:hover": {
                    boxShadow: "none",
                  },
                }}
                onClick={() => setBestShow(null)}
              >
                <Trans>
                  Understood
                </Trans>
              </Button>
            </Stack>
          </Modal>
        )}
      </ThemeProvider>
    </>
  );
}

export default ExpansionAwardsEsg;
