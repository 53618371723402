import React from "react";
import { Box, IconButton, Modal as MUIModal } from "@mui/material";
import styled from "@emotion/styled";
import { ThemeProvider } from "@mui/material/styles";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { theme } from "../theme";

function Modal({
  open,
  onClose,
  children,
  backgroundColor,
  width = 1200,
  ...rest
}) {
  return (
    <MUIModal open={open} onClose={onClose}>
      <ThemeProvider theme={theme}>
        <StyledBox backgroundColor={backgroundColor} width={width} p={4} {...rest}>
          <StyledIconPosition>
            <IconButton onClick={onClose} sx={{ "> svg > path": { color: "rgba(187, 188, 188, 1)" } }}>
              <FontAwesomeIcon style={{ width: "18px", height: "18px" }} icon={faXmark} />
            </IconButton>
          </StyledIconPosition>
          {children}
        </StyledBox>
      </ThemeProvider>
    </MUIModal>
  );
}

const StyledIconPosition = styled(Box)`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
`;

const StyledBox = styled(Box)(({ theme, width, backgroundColor }) => `
  background: ${backgroundColor || theme.palette.primary.main};
  width: ${width}px;
  border-radius: 5px;
  max-width: 88%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  * {
    color: ${backgroundColor !== "white" ? "white" : theme.palette.primary.main};
  }
  ${theme.breakpoints.down("md")} {
    max-height: 80%;
    overflow-y: auto;
    padding: ${theme.spacing(4)} ${theme.spacing(2)};
  }
`);

export default Modal;
